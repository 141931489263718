export const commonTypes = {
  APPLICATION_LOADED: 'APPLICATION_LOADED',
  APPLICATION_LOAD_REQ: 'APPLICATION_LOAD_REQ',
  PROCESSING_INITIATED: 'START_PROCESSING',
  PROCESSING_COMPLETED: 'FINISHED_PROCESSING',
  PROCESSING_COMPLETED_FORCE: 'FINISHED_PROCESSING_FORCE',
  LOOKUPS_DATA_REQ: 'LOOKUPS_DATA_REQ',
  SET_LOOKUPS_DATA: 'SET_LOOKUPS_DATA',
  SYSOPT_DATA_REQ: 'SYSOPT_DATA_REQ',
  SET_SYSOPT_DATA: 'SET_SYSOPT_DATA',
  GET_OPERATOR_LIST: 'GET_OPERATOR_LIST',
  SET_OPERATOR_LIST: 'SET_OPERATOR_LIST',
  SET_FILTER_DATA: 'SET_FILTER_DATA',
  GET_DEFAULT_FILTER_DATA: 'GET_DEFAULT_FILTER_DATA',
  GET_FILTER_LIST: 'GET_FILTER_LIST',
  SET_FILTER_LIST: 'SET_FILTER_LIST',
  UPSERT_FILTER_REQUEST: 'UPSERT_FILTER_REQUEST',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  GET_SUB_MODULE_DETAILS: 'GET_SUB_MODULE_DETAILS',
  GET_DEFAULT_FILTER_SETTINGS: 'GET_DEFAULT_FILTER_SETTINGS',
  GET_ROLES_LIST: 'GET_ROLES_LIST',
  SET_ROLE_LIST: 'SET_ROLE_LIST',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  DELETE_FILTER_REQUEST: 'DELETE_FILTER_REQUEST',
  SET_FILTER_LIST_COUNT: 'SET_FILTER_LIST_COUNT',
  GET_USER_DATA: 'GET_USER_DATA',
  SET_USER_DATA: 'SET_USER_DATA',
  GET_RBAC_DATA: 'GET_RBAC_DATA',
  SET_USER_CONTACT_INFO: 'SET_USER_CONTACT_INFO',
  GET_USER_CONTACT_INFO: 'GET_USER_CONTACT_INFO',
  SET_RBAC_DATA: 'SET_RBAC_DATA',
  SET_DIRTY_DATA: 'SET_DIRTY_DATA',
  UPDATE_USED_ON_FILTER_REQUEST: 'UPDATE_USED_ON_FILTER_REQUEST',
  GET_USER_PREF_DATA: 'GET_USER_PREF_DATA',
  SET_USER_PREF_DATA: 'SET_USER_PREF_DATA',
  SET_USER_PREF_ERROR: 'SET_USER_PREF_ERROR',
  SET_NOT_LOGIN: 'SET_NOT_LOGIN',
  GET_BATCH_PROCESSING_STATUS: 'GET_BATCH_PROCESSING_STATUS',
  SET_BATCH_PROCESSING_STATUS: 'SET_BATCH_PROCESSING_STATUS',
  RELEASE_LOCK: 'RELEASE_LOCK',
  SET_LOCK: 'SET_LOCK',
  REMOVE_LOCK: 'REMOVE_LOCK',
  GET_BATCH_EXPORT_DATA: 'GET_BATCH_EXPORT_DATA',
  SET_BATCH_EXPORT_DATA: 'SET_BATCH_EXPORT_DATA',
  DELETE_BATCH_EXPORT_JOB_REQUEST: 'DELETE_BATCH_EXPORT_JOB_REQUEST',
  GET_SYS_CONFIG: 'GET_SYS_CONFIG',
  DOWNLOAD_BATCH_EXPORT_JOB_REQ: 'DOWNLOAD_BATCH_EXPORT_JOB_REQ',
  RESET_BATCH_VALUE: 'RESET_BATCH_VALUE',
  GET_SYSTEM_RUN_DATE: 'GET_SYSTEM_RUN_DATE',
  SET_SYS_RUN_DATE: 'SET_SYS_RUN_DATE',
  RESET_SYS_RUN_DATE: 'RESET_SYS_RUN_DATE',
  GET_JOB_REQUEST_STATUS_BY_JOB_ID: 'GET_JOB_REQUEST_STATUS_BY_JOB_ID',
  RESETSTORE: 'RESET_STORE',
  SET_SCREEN_TIME: 'SET_SCREEN_TIME',
  STORE_SCREEN_TIME: 'STORE_SCREEN_TIME',
  GETALLQUICKSLINKS: 'GETALLQUICKSLINKS',
  GETIARDATA: 'GETIARDATA',
  SETIARDATA: 'SETIARDATA',
  GETUSERQUICKSLINKS: 'GETUSERQUICKSLINKS',
  SETALLQUICKSLINKS: 'SETALLQUICKSLINKS',
  SETUSERQUICKSLINKS: 'SETUSERQUICKSLINKS',
  SAVEUSERQUICKSLINKS: 'SAVEUSERQUICKSLINKS',
  RESET_UPSERT_VALUE_MAIN_STORE: 'RESET_UPSERT_VALUE_MAIN_STORE',
  GETCOMPSTATELIST: 'GET_COMP_STATE_LIST',
  SET_EXTERNAL_USER: 'SET_EXTERNAL_USER',
  GET_LEVEL_RBAC_DATA: 'GET_LEVEL_RBAC_DATA',
  SET_LEVEL_RBAC_DATA: 'SET_LEVEL_RBAC_DATA',
  SAVEUSERLANG: 'SAVEUSERLANG',
  SET_USER_LANG: 'SET_USER_LANG',
  GET_CACHE_UPDATE: 'GET_CACHE_UPDATE',
};

export const applicationLoadedSuccessfully = () => ({
  type: commonTypes.APPLICATION_LOADED,
  data: { isLoaded: true },
});

export const applicationFailedToLoad = () => ({
  type: commonTypes.APPLICATION_LOADED,
  data: { isLoaded: false },
});

export const processingStarted = () => ({
  type: commonTypes.PROCESSING_INITIATED,
  data: {},
});

export const processingCompleted = () => ({
  type: commonTypes.PROCESSING_COMPLETED,
  data: {},
});

export const processingCompletedForce = () => ({
  type: commonTypes.PROCESSING_COMPLETED_FORCE,
  data: {},
});
/**
 * Action for requesting payload data from server
 * @param {object} payload
 * @returns object
 */
export const lookUpsDataProcessing = (payload) => ({
  type: commonTypes.LOOKUPS_DATA_REQ,
  data: payload,
});
export const sysOptDataProcessing = (payload) => ({
  type: commonTypes.SYSOPT_DATA_REQ,
  data: payload,
});

/**
 * Action to set the lookups data to the redux state
 * @param {object} payload
 * @returns object
 */
export const setLookUpsData = (payload) => ({
  type: commonTypes.SET_LOOKUPS_DATA,
  data: payload,
});
export const setSysOptData = (payload) => ({
  type: commonTypes.SET_SYSOPT_DATA,
  data: payload,
});

export const getOperatorList = () => ({
  type: commonTypes.GET_OPERATOR_LIST,
});

export const setOperatorList = (payload) => ({
  type: commonTypes.SET_OPERATOR_LIST,
  data: payload,
});

export const setFilterSettings = (payload) => ({
  type: commonTypes.SET_FILTER_DATA,
  data: payload,
});

export const getDefaultFilterSettings = (payload) => ({
  type: commonTypes.GET_DEFAULT_FILTER_DATA,
  data: payload,
});

export const getCustomFilterList = (payload) => ({
  type: commonTypes.GET_FILTER_LIST,
  data: payload,
});

export const setFilterList = (payload) => ({
  type: commonTypes.SET_FILTER_LIST,
  data: payload,
});

export const setFilterListCount = (payload) => ({
  type: commonTypes.SET_FILTER_LIST_COUNT,
  data: payload,
});

export const upsertFilter = (payload) => ({
  type: commonTypes.UPSERT_FILTER_REQUEST,
  data: payload,
});

export const updateUseOnFilter = (payload) => ({
  type: commonTypes.UPDATE_USED_ON_FILTER_REQUEST,
  data: payload,
});

export const resetUpsertValue = (payload) => ({
  type: commonTypes.RESET_UPSERT_VALUE,
  data: payload,
});

export const resetUpsertError = () => ({
  type: commonTypes.RESET_UPSERT_ERROR,
});

/**
 *
 * @param {object} payload
 * @returns payload for for fetching the sub-modile details
 */
export const getSubModuleConfiguration = (payload) => ({
  type: commonTypes.GET_SUB_MODULE_DETAILS,
  data: payload,
});
/**
 * payload for for fetching the default filter settings details
 * @param {object} payload
 * @returns payload for for fetching the default filter settings details
 */
export const getDeafultFilterSettings = (payload) => ({
  type: commonTypes.GET_DEFAULT_FILTER_SETTINGS,
  data: payload,
});

/**
 * Get Role List from Database
 */
export const getRolesList = () => ({
  type: commonTypes.GET_ROLES_LIST,
});

/**
 * set available list in redux store
 * @param {array} payload
 * @returns
 */
export const setRoleList = (payload) => ({
  type: commonTypes.SET_ROLE_LIST,
  data: payload,
});

/**
 * reset delete data in redux store
 * @param {array} payload
 * @returns
 */
export const resetDeleteValue = (payload) => ({
  type: commonTypes.RESET_DELETE_VALUE,
  data: payload,
});

/**
 * delete Filter Request
 * @param {array} payload
 * @returns
 */
export const deleteFilterRequest = (payload) => ({
  type: commonTypes.DELETE_FILTER_REQUEST,
  data: payload,
});
/**
 * reset UsersData data in redux store
 * * @returns
 */
export const usersDataProcessing = () => ({
  type: commonTypes.GET_USER_DATA,
});
/**
 * reset UsersData data in redux store
 * * @returns
 */
export const setUserData = (payload) => ({
  type: commonTypes.SET_USER_DATA,
  data: payload,
});

/**
 * reset get RBACData data in redux store
 * * @returns
 */
export const getRBACData = (payload = null) => ({
  type: commonTypes.GET_RBAC_DATA,
  data: payload,
});
/**
 * reset UsersData data in redux store
 * * @returns
 */
export const setUserContactInfo = (payload) => ({
  type: commonTypes.SET_USER_CONTACT_INFO,
  data: payload,
});

/**
 * reset get RBACData data in redux store
 * * @returns
 */
export const getUserContactInfo = (payload = null) => ({
  type: commonTypes.GET_USER_CONTACT_INFO,
  data: payload,
});
/**
 * reset RBACData data in redux store
 * * @returns
 */
export const setRBACData = (payload) => ({
  type: commonTypes.SET_RBAC_DATA,
  data: payload,
});

/**
 * reset get user preference data in redux store
 * * @returns
 */
export const getUserPrefData = (payload) => ({
  type: commonTypes.GET_USER_PREF_DATA,
  data: payload,
});
/**
 * reset user preference data in redux store
 * * @returns
 */

export const setUserPrefData = (payload) => ({
  type: commonTypes.SET_USER_PREF_DATA,
  data: payload,
});
/**
 * reset user preference data in redux store
 * * @returns
 */

export const setUserPrefError = (payload) => ({
  type: commonTypes.SET_USER_PREF_ERROR,
  data: payload,
});

/**
 * reset get user preference data in redux store
 * * @returns
 */
export const getBatchExportData = (payload) => ({
  type: commonTypes.GET_BATCH_EXPORT_DATA,
  data: payload,
});
/**
 * reset user preference data in redux store
 * * @returns
 */

export const setBatchExportData = (data) => ({
  type: commonTypes.SET_BATCH_EXPORT_DATA,
  data,
});
/**
 * dirty data function for cancel confirmation
 */
export const checkDirtyData = (payload = false) => ({
  type: commonTypes.SET_DIRTY_DATA,
  data: payload,
});
/**
 * reset setNotLogin data in redux store
 * * @returns
 */
export const setNotLogin = (payload) => ({
  type: commonTypes.SET_NOT_LOGIN,
  data: payload,
});

export const getBatchProcessingState = (payload) => ({
  type: commonTypes.GET_BATCH_PROCESSING_STATUS,
  data: payload,
});

export const setBatchProcessingState = (data) => ({
  type: commonTypes.SET_BATCH_PROCESSING_STATUS,
  data,
});

export const releaseLockCombo = (data) => ({
  type: commonTypes.RELEASE_LOCK,
  data,
});

export const setLock = (data) => ({
  type: commonTypes.SET_LOCK,
  data,
});

export const removeLockCombo = (data) => ({
  type: commonTypes.REMOVE_LOCK,
  data,
});

export const getSystemConfig = (payload) => ({
  type: commonTypes.GET_SYS_CONFIG,
  data: payload,
});

/**
 * reset delete data in redux store
 * @param {array} payload
 * @returns
 */
export const resetBatchValue = (payload) => ({
  type: commonTypes.RESET_BATCH_VALUE,
  data: payload,
});
export const resetStore = () => ({
  type: commonTypes.RESETSTORE,
});

export const setScreenTime = (payload) => ({
  type: commonTypes.STORE_SCREEN_TIME,
  data: payload,
});

/**
 * Get all quicks links as per security role
 * @returns
 */
export const getAllQuicksLinks = (payload) => ({
  type: commonTypes.GETALLQUICKSLINKS,
  data: payload,
});
/* *Get all InteractiveActivityReport dashboard data as per security role
* @returns
*/
export const getIARdata = (payload) => ({
  type: commonTypes.GETIARDATA,
  data: payload,
});
/**
 * Get all quicks links as per user selection
 * @returns
 */
export const getUserQuicksLinks = () => ({
  type: commonTypes.GETUSERQUICKSLINKS,
});
/**
 * Save quicks links as per user selection
 * @returns
 */
export const saveUserSelQuickLinks = (payload) => ({
  type: commonTypes.SAVEUSERQUICKSLINKS,
  data: payload,
});

export const getCompStateList = (payload) => ({
  type: commonTypes.GETCOMPSTATELIST,
  data: payload,
});

export const setExternalUser = (payload) => ({
  type: commonTypes.SET_EXTERNAL_USER,
  data: payload,
});

/**
 * reset get Level RBACData data in redux store
 * * @returns
 */
export const getLevelRBACData = (payload = null) => ({
  type: commonTypes.GET_LEVEL_RBAC_DATA,
  data: payload,
});
/**
 * reset Level RBACData data in redux store
 * * @returns
 */
export const setLevelRBACData = (payload) => ({
  type: commonTypes.SET_LEVEL_RBAC_DATA,
  data: payload,
});
/**
 * Save language as per user selection
 * @returns
 */
export const saveUserLang = (payload) => ({
  type: commonTypes.SAVEUSERLANG,
  data: payload,
});
/**
 * reset User lang data in redux store
 * * @returns
 */
export const setUserLang = (payload) => ({
  type: commonTypes.SET_USER_LANG,
  data: payload,
});
